import { DefaultTheme } from '@rsc/ui-components/theme';
import styles from './utils/styles';
import themeColors from './themeColors';

// use shared template and extend/override with custom theme values

import PetSafeLogo from '../images/petsafe-logo.svg';

const theme = {
  ...DefaultTheme,
  button: {
    ...DefaultTheme.button,
    borderRadius: '8px',
    primary: {
      ...DefaultTheme.button.primary,
      background: styles.colors.primary[100],
      backgroundHover: styles.colors.primary[200],
      backgroundActive: styles.colors.blue[900],
      backgroundDisabled: styles.colors.blue[600],
      textColor: styles.colors.primary[900],
    },
    secondary: {
      ...DefaultTheme.button.secondary,
      background: 'transparent',
      backgroundHover: styles.colors.accent[300],
      backgroundActive: styles.colors.gray[300],
      backgroundDisabled: 'white',
      borderColor: styles.colors.neutral[900],
      textColor: styles.colors.neutral[900],
    },
    tertiary: {
      ...DefaultTheme.button.tertiary,
      background: styles.colors.blue[500],
      backgroundHover: styles.colors.blue[700],
      backgroundActive: styles.colors.blue[900],
      backgroundDisabled: styles.colors.blue[600],
      borderColor: styles.colors.blue[500],
    },
  },
  input: {
    borderRadius: '8px',
  },
  colors: {
    ...DefaultTheme.colors,
    ...themeColors,
  },
  hero: {
    fontColor: 'white',
    span: {
      textTransform: 'none',
    },
  },
  images: {
    logo: PetSafeLogo,
    mobileLogo: PetSafeLogo,
  },
  name: 'PetSafe Theme',
  notificationBanner: {
    background: 'white',
    text: styles.colors.blue[500],
  },
  productCard: {
    ...DefaultTheme.productCard,
    borderColorHover: styles.colors.gray[100],
    boxShadowHover: 'none',
    priceColor: styles.colors.blue[500],
    titleColor: styles.colors.blue[500],
    titleColorHover: styles.colors.blue[500],
    titleTextTransform: 'inherit',
  },
  fontWeight: {
    ...DefaultTheme.fontWeight,
  },
  form: {
    flexDirection: 'column-reverse',
    button: {
      width: '250px',
      marginTop: '16px',
    },
    input: {
      borderRadius: '8px',
    },
  },
  addressBox: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default theme;
