import { useStaticQuery, graphql } from 'gatsby';

const useSiteSettings = () => {
  const { sanitySiteSettings } = useStaticQuery(
    graphql`
      query SanitySiteSettingsHookPetSafeQuery {
        sanitySiteSettings(
          _type: { eq: "siteSettings" }
          siteIdentifier: { eq: "petsafe" }
        ) {
          phone
          contactPage {
            slug {
              current
            }
          }
          ordersPageHeadline
          ordersPageSubHeadline
          subscriptionsPageHeadline
          subscriptionsPageSubHeadline
          videosPageHeadline
          videosPageSubHeadline
          addressesPageHeadline
          addressesPageSubHeadline
          settingsPageHeadline
          settingsPageSubHeadline
        }
      }
    `
  );

  const defaultSettings = {
    contactPage: '/support/contact-us/',
    phone: '1-866-738-4379',
    ordersPageHeadline: 'Your Orders',
    ordersPageSubHeadline: 'View your order history and manage your account.',
    subscriptionsPageHeadline: 'Subscriptions',
    subscriptionsPageSubHeadline:
      'Manage your subscriptions, update payment methods, and view subscription details here.',
    videosPageHeadline: 'Training Videos',
    videosPageSubHeadline:
      'Exclusive training videos to help you train your pet with Invisible Fence technology.',
    addressesPageHeadline: 'Your Addresses',
    addressesPageSubHeadline: 'Manage your delivery addresses here.',
    settingsPageHeadline: 'Your Account',
    settingsPageSubHeadline: 'Manage your account details and password here.'
  };

  const settings = { ...defaultSettings, ...sanitySiteSettings };

  // Ensure contactPage slug starts with '/'
  if (sanitySiteSettings?.contactPage?.slug?.current) {
    settings.contactPage =
      sanitySiteSettings.contactPage.slug.current.startsWith('/')
        ? sanitySiteSettings.contactPage.slug.current
        : `/${sanitySiteSettings.contactPage.slug.current}`;
  }

  return settings;
};

export default useSiteSettings;
